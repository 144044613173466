const analysisCoupon = {
  click_tickmorecopons () {
    sa('send', { activity_name: 'click_tickmorecopons' })
  },
  clickCancelCoupon () {
    sa('send', { activity_name: 'click_coupon_code_cancel', activity_param: '' })
  },
  SelectAvailablePayMethod () {
    sa('send', { activity_name: 'expose_popup_couponselectpaymethodbox' })
  },
  ApplyCouponNotFreeShipping () {
    sa('send', { 'activity_name': 'expose_popup_notfreeshipping' })
  }

}

export default analysisCoupon
