import schttp from 'public/src/services/schttp'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { checkoutReport as reportBankDialog }  from 'public/src/pages/checkout/tools/report_bank_dialog.js'
import { fetchTelCountryListApi } from 'public/src/services/api/pay/checkout.js'

export function bankSelectStateFactory() {
  return {
    // 支付方式银行配置
    banksConfig: {},
    bankDrawerConfig: {
      visible: false,
      bankList: [],
      paymentMethod: '',
      defaultCode: ''
    },
    // 南非capitec银行信息
    capitecDialog: {
      visible: false,
      isCollect: false,
      isDlocalEft: false,
      isDlocalPse: false,
      phone: '',
      email: '',
      countryId: '',
      paymentMethod: '',
      bankInfo: {},
      phoneCountryNum: '',
      phoneRule: {
        // 'reg': '^[0-9]{5,30}$',
        // 'copywrite': 'Phone Number should contain 5-30 digits no other characters.'
      },
      emailRule: {
        reg: /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(\]?)$/,
        copywrite: ''
      },
      collectEmail: false,
      collectPhoneNumber: false
    },
  }
}
export default {
  namespaced: true,
  state: bankSelectStateFactory,
  getters: {
    // 对应支付方式选中的银行信息
    paymentBankInfo(state, getters, rootState) {
      console.log('paymentBankInfo---', state, getters, rootState)
      return state.banksConfig
    }
  },
  mutations: {
    updateBankDrawerConfig(state, payload) {
      state.bankDrawerConfig = Object.assign(
        {},
        state.bankDrawerConfig,
        payload
      )
    },
    updateBanksConfig(state, payload = {}) {
      state.banksConfig = Object.assign({}, state.banksConfig, payload)
    },
    updateCapitecDialog(state, payload = {}) {
      state.capitecDialog = Object.assign({}, state.capitecDialog, payload)
    },
  },
  actions: {
    async fetchUserPaymentRelatedInfo({ state, commit }, code) {
      let res = await schttp({
        url: '/api/checkout/userPaymentRelatedInfo',
        params: {
          paymentCode: code
        }
      })
      let phone = state.capitecDialog.phone || ''
      let email = await UserInfoManager.get({ key: 'pwa_user_email' }) || ''
      if (res.code === '0' && res.info) {
        if (res.info.email) email = res.info.email
        if (res.info.phoneNumber) phone = res.info.phoneNumber 
      } 
      commit('updateCapitecDialog', {
        visible: true,
        phone: phone,
        email: email
      })
    },
    // 获取email信息
    async fetchUserEmailInfo({ commit }, code) {
      let res = await schttp({
        url: '/api/checkout/userPaymentRelatedInfo',
        params: {
          paymentCode: code
        }
      })
      let email = await UserInfoManager.get({ key: 'pwa_user_email' }) || ''
      if (res.code === '0' && res.info) {
        if (res.info.email) email = res.info.email
      } 
      commit('updateCapitecDialog', {
        visible: true,
        email: email
      })
    },
    // 获取用户电话号信息
    async fetchUserPhoneInfo({ state, commit }, code) {
      let res = await schttp({
        url: '/api/checkout/userPaymentRelatedInfo',
        params: {
          paymentCode: code
        }
      })
      let phone = state.capitecDialog.phone || ''
      if (res.code === '0' && res.info) {
        if (res.info.phoneNumber) phone = res.info.phoneNumber 
      } 
      commit('updateCapitecDialog', {
        visible: true,
        phone: phone,
      })
    },
    async fetchTelCheckRuleApi({ state, commit }) {
      let countryId = state.capitecDialog.countryId || '196'
      let res = await schttp({
        url: '/api/checkout/telCheckRule',
        params: {
          country_id: countryId
        }
      })
      if (res.code === '0' && res.info?.result?.regex_list?.length) {
        let phoneRule = res.info.result.regex_list[0]
        commit('updateCapitecDialog', {
          phoneRule: phoneRule
        })
      }
    },
    async fetchTelCountryList({ state, commit }) {
      let countryId = state.capitecDialog.countryId || '196'
      let res = await fetchTelCountryListApi({
        country_id: countryId
      })
      if (res.isOk) {
        let phoneCountryNum = `${res.info.value}+${res.info.countrynum}`
        commit('updateCapitecDialog', {
          phoneCountryNum: phoneCountryNum
        })
      }
    },
    handleCapitecDialogEvent({ state, commit, dispatch }, payload) {
      reportBankDialog(payload)
      if(payload.type === 'submit'){
        commit('updateCapitecDialog', {
          visible: false,
          phone: payload.phone,
          email: payload.email
        })
        dispatch('saveSelectBankAndPay', state.capitecDialog.bankInfo)
      }
    },
    // 选择银行
    async handleBankSelect({ commit, state, dispatch }, bankItem) {
      const { bankDrawerConfig = {} } = state
      if (bankDrawerConfig.paymentMethod === 'dlocal-pse') {
        commit('updateCapitecDialog', { paymentMethod: bankDrawerConfig.paymentMethod, bankInfo: bankItem, 
          isDlocalPse: bankDrawerConfig.paymentMethod === 'dlocal-pse', isDlocalEft: false })
        dispatch('fetchUserEmailInfo', bankDrawerConfig.paymentMethod)
      } else if (
        bankDrawerConfig.paymentMethod === 'dlocal-eft' &&
        bankItem.name === 'Capitec bank'
      ) {
        commit('updateCapitecDialog', { paymentMethod: bankDrawerConfig.paymentMethod, bankInfo: bankItem, isCollect: true, isDlocalEft: true, isDlocalPse: false })
        dispatch('fetchUserPaymentRelatedInfo', bankDrawerConfig.paymentMethod)
        dispatch('fetchTelCheckRuleApi')
        dispatch('fetchTelCountryList')
      } else {
        commit('updateCapitecDialog', { bankInfo: {}, isCollect: false, isDlocalEft: false, isDlocalPse: false })
        dispatch('saveSelectBank', bankItem)
      }
    },
    saveSelectBank({ commit, state }, bankItem) {
      const { banksConfig = {}, bankDrawerConfig = {} } = state
      const configValue = banksConfig[bankDrawerConfig.paymentMethod] || {}
      console.log(
        'bankDrawerConfig.paymentMethod----',
        state,
        bankDrawerConfig.paymentMethod
      )

      commit('updateBanksConfig', {
        [bankDrawerConfig.paymentMethod]: {
          ...configValue,
          bankCode: bankItem.code,
          bankCodeText: bankItem.name,
          bankLogo: bankItem.logo,
          error: false
        }
      })
      commit('updateBankDrawerConfig', {
        visible: false,
        paymentMethod: '',
        defaultCode: bankItem?.code
      })
    },
    saveSelectBankAndPay({ dispatch }, bankItem) {
      dispatch('saveSelectBank', bankItem)
      // 快捷支付
      window?.appEventCenter?.$emit('capitecBankSaved')
    },
    // 弹起收集信息弹框
    handleTelEmailDialog({ commit, state, dispatch }, bankItem){
      // 没有银行，弹起收集信息弹框
      // collectEmail:是否收集邮箱
      // collectPhoneNumber:是否收集电话号码
      const { bankDrawerConfig = {}, capitecDialog = {} } = state
      const { collectEmail = false, collectPhoneNumber = false } = capitecDialog
      console.log('@@@ [弹起收集信息弹框] capitecDialog>>', capitecDialog)
      if(collectEmail && collectPhoneNumber) {
        commit('updateCapitecDialog', { paymentMethod: bankDrawerConfig.paymentMethod, bankInfo: bankItem, isCollect: true })
        dispatch('fetchUserPaymentRelatedInfo', bankDrawerConfig.paymentMethod)
        dispatch('fetchTelCheckRuleApi')
        dispatch('fetchTelCountryList')
      } else if(collectEmail) {
        commit('updateCapitecDialog', { paymentMethod: bankDrawerConfig.paymentMethod, bankInfo: bankItem, 
          collectEmail, collectPhoneNumber })
        dispatch('fetchUserEmailInfo', bankDrawerConfig.paymentMethod)
      } else if(collectPhoneNumber) {
        commit('updateCapitecDialog', { paymentMethod: bankDrawerConfig.paymentMethod, bankInfo: bankItem, 
          collectEmail, collectPhoneNumber })
        dispatch('fetchUserPhoneInfo', bankDrawerConfig.paymentMethod)
        dispatch('fetchTelCheckRuleApi')
        dispatch('fetchTelCountryList')
      }
    },
    // 弹起银行弹框/收集信息弹框
    handleStartSelectBank({ commit, state, dispatch }, payment, userInfoCheckError = false) {
      let code = ''
      // payment的类型应该为object类型，防止有遗漏的传了string类型（已兼容）
      // 无论payment是什么类型，如果有支付方式里有银行列表，则弹起银行列表
      if(typeof payment === 'object') {
        commit('updateCapitecDialog', {
          collectEmail: payment.collect_email == 1,
          collectPhoneNumber: payment.collect_phone_number == 1
        })
        code = payment.code
      } else if(typeof payment === 'string'){
        code = payment
      } else {
        return
      }

      // 如果收集用户信息，判空校验未通过
      if(userInfoCheckError) {
        // 收集信息弹框
        dispatch('handleTelEmailDialog', state.capitecDialog?.bankInfo)
        return
      }
      
      if(state.banksConfig[code]?.list?.length){
        // 有银行，则弹起银行列表
        commit('updateBankDrawerConfig', {
          defaultCode: state.banksConfig[code]?.bankCode || '',
          visible: true,
          bankList: state.banksConfig[code].list,
          paymentMethod: code
        })
      }
    },
    resetBanksConfig({ commit }, resetData) {
      commit('updateBanksConfig', resetData)
    }
  }
}

