const BeltType = {
  // 大件海陆运
  LargeShipGood: 'largeshipgood',
  // 赠品
  Gift: 'gift',
  // 会员赠品
  SheinClubGift: 'sheinclubgift',
  // 附加商品
  AddOnItem: 'addonitem',
  // 售罄
  OutOfStock: 'outofstock',
  // 已售数量
  HotSale: 'hotsale',
  // 闪购
  FlashSale: 'flashsale',
  // 低价
  Cheaper: 'cheaper',
  // 即将售罄
  AlmostSoldOut: 'soldout',
}

const ShoppingBagScene = {
  // 外层页面处购物袋
  InPage: 'InPage',
  // 购物袋弹窗
  InDrawer: 'InDrawer'
}

const beltPriority = (scene, abt) => {
  switch (scene) {
    case ShoppingBagScene.InPage:
      return [
        BeltType.LargeShipGood, 
        BeltType.OutOfStock, 
        BeltType.Gift,
        BeltType.SheinClubGift,
        BeltType.AddOnItem,
        ...abt
      ]
    case ShoppingBagScene.InDrawer:
      return [
        BeltType.SheinClubGift,
        ...abt
      ]
    default:
      return []
  }
}

// 不可退类型
const NonRefundableType = {
  // 定制商品不可退
  Customized: 'Customized',
  // 品类不可退
  Category: 'Category',
  // 促销不可退
  Promotion: 'Promotion',
  // 低毛利不可退
  LowGrossMargin: 'LowGrossMargin',
  // 撤销权不可退（de市场）
  Withdrawal: 'Withdrawal',
  // 撤销权可退（促销不可退、低毛利不可退、品类不可退）（de市场）
  RefundableWithdrawal: 'RefundableWithdrawal'
}

// 底部提示类型
const FooterTipsType = {
  // 积分
  ExtraPoints: 'ExtraPoints',
  // 商品不可退
  NonRefundable: 'NonRefundable',
  // 细分不可退类型
  ...NonRefundableType
}

// 不可退优先级
const nonRefundablePriority = {
  default: [
    NonRefundableType.Customized,
    NonRefundableType.Category,
    NonRefundableType.Promotion,
    NonRefundableType.LowGrossMargin
  ],
  de: [
    NonRefundableType.Customized,
    NonRefundableType.Withdrawal,
    NonRefundableType.RefundableWithdrawal
  ]
}

export {
  BeltType,
  ShoppingBagScene,
  beltPriority,
  FooterTipsType,
  NonRefundableType,
  nonRefundablePriority
}