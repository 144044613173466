
import { isArray, isNumber } from '@shein/common-function'
import { deepCopy, scenceBuildResult } from 'public/src/pages/checkout/utils.js'

const checkoutDialogComponentMap = {
  soldOutModalShow: 'soldOutIsLoaded', // 售罄弹窗
  specialGoodsModalShow: 'specialGoodsIsLoaded', // 南非特殊商品下单限制弹窗
  couponPrivacyItem: 'myCouponPrivacyItemIsLoaded',
  chooseCouponDrawer: 'myCouponListIsLoaded',
  giftCardDrawer: 'giftCardIsLoaded',
  couponGiftAddItem: 'couponGiftAddItemIsLoaded',
  showAddrPage: 'addressListPageIsLoaded',
  shoppingBagModalShow: 'shoppingBagModalIsLoaded',
  showPaymentCoupon: 'paymentCouponIsLoaded',
  showShippingDayPercent: 'shippingDayPercentIsLoaded',
  showPointInputPanel: 'pointIsLoaded',
  showWalletInputPanel: 'WalletIsLoaded',
  showPrimeDetail: 'primeDetailIsLoaded',
  freezTipsAndGoods: 'freezTipsAndGoodsIsLoaded',
  currencyChangeTips: 'checkoutDialogIsLoaded',
  codConfirm: 'checkoutDialogIsLoaded',
  confirmUsePaymentCouponShow: 'checkoutDialogIsLoaded',
  showShippingFeeDrawer: 'shippingFeeIsLoaded',
  showXtraCoupon: 'xtraCouponIsLoaded',
  showBagTax: 'bagTaxIsLoaded',
  showShippingLimitGoods: 'shippingLimitGoodsIsLoaded',
  showShippingLimitAddress: 'shippingLimitAddressIsLoaded',
  showShippingLimitStore: 'shippingLimitStoreIsLoaded',
  shippingTypeDialog: 'shippingTypeDialogIsLoaded',
  showShoppingBagRetain: 'shoppingBagRetainDialogIsLoaded',
  showRecheckQuickship: 'showRecheckQuickshipIsLoaded'
}

const mutations = {
  resetLocals(state) {
    if (state?.locals) return
    if (typeof window === 'undefined') return

    const { lang, host, langPath, NODE_SERVER_ENV, IMG_LINK, IS_RW, SiteUID, PUBLIC_CDN, SHOP_TRANSIT, GB_cssRight, RESOURCE_SDK, MULTIPLE_TAXES_SPLIT_DISPLAY, COUPON_COUNTDOWN_THRESHOLD, SERVER_TYPE, CUSTOMIZATION_PRODUCT_IMAGE_DOMAINS } = gbCommonInfo

    state.locals = { lang, host, langPath, NODE_SERVER_ENV, IMG_LINK, IS_RW, SiteUID, PUBLIC_CDN, SHOP_TRANSIT, GB_cssRight, RESOURCE_SDK, MULTIPLE_TAXES_SPLIT_DISPLAY, COUPON_COUNTDOWN_THRESHOLD, SERVER_TYPE, CUSTOMIZATION_PRODUCT_IMAGE_DOMAINS }
  },
  assignState(state, payload) {
    if (!state.asyncComponentLoadedStatus.checkoutDialogIsLoaded) {
      ['showDiscountLimitTip', 'showCartLimitTip', 'showFlashLimitTip', 'p65TipsShow', 'isShowQuickPop', 'isShowQsFreight', 'showEnvironMental', 'showtip'].forEach(key => {
        if (payload[key] && !state.asyncComponentLoadedStatus.checkoutDialogIsLoaded) {
          state.loading = true
          state.asyncComponentLoadedStatus.checkoutDialogIsLoaded = true
        }
      })
    }

    Object.assign(state, payload)
  },
  changeTogetherBuy(state, payload) {
    Object.keys(payload).forEach(key => {
      state.togetherBuy[key] = payload[key]
    })
  },
  changeParamsStatus(state, { type, params }) {
    if (type == 'pageStatus') {
      Object.keys(params).forEach(key => {
        if (checkoutDialogComponentMap[key] && params[key]) {
          if (!state.asyncComponentLoadedStatus[checkoutDialogComponentMap[key]]) {
            state.loading = true
          }
          state.asyncComponentLoadedStatus[checkoutDialogComponentMap[key]] = true
        }
      })
    }
    if (['freezTipsAndGoods', 'currencyChangeTips', 'codConfirm'].includes(type) && params['show']) {
      if (!state.asyncComponentLoadedStatus[checkoutDialogComponentMap[type]]) {
        state.loading = true
      }
      state.asyncComponentLoadedStatus[checkoutDialogComponentMap[type]] = true
    }
    state[type] = { ...state[type], ...params }
  },
  clickStoreAddress(state, { type, vm, extraData = {} }) {
    if (vm) {
      const url = type === 'supplement' ? '/api/user/addressbook/extraAddress/update' : '/api/user/addressbook/storeAddress/update'
      vm.updateUI({
        type,
        addrData: state.storeData,
        url,
        countryId: state.checkout.default_address.countryId,
        pageName: '下单页',
        extraData
      })
    }
  },
  showMaskTips(state, params) {
    state.blackMask.showMask = true
    state.blackMask.text = params?.text || params || ''
    state.blackMask.type = params?.type || 0
    if (typeof params?.cb === 'function') {
      state.blackMask.cb = params?.cb
    }
  },
  closeMaskTips(state, info) {
    state.blackMask.showMask = false
    if (typeof state.blackMask?.cb === 'function') {
      state.blackMask.cb(info)
      state.blackMask.cb = null
    }
  },
  updatePaymentMethods(state, { list, folded_position, originFoldedPosition, bin_discount_info, bin_discount_list }) {
    if (state.checkout.results) {
      if (isArray(list)) {
        state.checkout.results.paymentMethods = list
      }
      if (isNumber(folded_position)) {
        state.checkout.results.folded_position = folded_position
      }
      if (isNumber(originFoldedPosition)) {
        state.checkout.results.originFoldedPosition = originFoldedPosition
      }
      if (bin_discount_info) {
        state.checkout.results.bin_discount_info = bin_discount_info
      }
      if (isArray(bin_discount_list)) {
        state.checkout.results.bin_discount_list = bin_discount_list
      }
    }
  },
  updateAddressList(state, list) {
    list.forEach((item, index) => {
      state.addresslist.splice(index, 1, item)
    })
  },
  updatePaymentLogoInfo(state, payload) {
    state.paymentLogoInfo = payload || {}
  },
  updateCheckoutGuideLogoList(state, payload) {
    state.checkoutGuideLogoList = payload || []
  },
  updateState(state, payload) {
    const { key, value } = payload
    if (checkoutDialogComponentMap[key] && value) {
      if (!state.asyncComponentLoadedStatus[checkoutDialogComponentMap[key]]) {
        state.loading = true
      }
      state.asyncComponentLoadedStatus[checkoutDialogComponentMap[key]] = true
    }
    if (state[key] !== undefined) {
      state[key] = value
    }
  },
  updateBankDrawerConfig(state, payload) {
    Object.assign(state.bankDrawerConfig, payload)
  },
  showPageLoading(state, { duration = 800 } = {}) {
    if (state.loadingParam.timer) {
      clearTimeout(state.loadingParam.timer)
      state.loadingParam.timer = null
    }
    state.loadingParam.count += 1
    state.loadingParam.timer = setTimeout(() => {
      state.loadingParam.loading = true
    }, duration)
  },
  hidePageLoading(state) {
    if (state.loadingParam.count <= 0) return
    state.loadingParam.count -= 1

    if (state.loadingParam.count > 0) return
    state.loadingParam.loading = false
    if (state.loadingParam.timer) {
      clearTimeout(state.loadingParam.timer)
      state.loadingParam.timer = null
    }
  },
  updateSelectedPrime(state, { index, isUpdateRightList }) {
    state.selectdPrimeIndex = index
    if (isUpdateRightList) {
      let rightConfigList = state.primeProductList[state.selectdPrimeIndex || 0]?.right_config_list || []
      state.rightConfigList = rightConfigList
    }
  },
  changeVirtualCouponInfo(state, payload) {
    Object.keys(payload).forEach(key => {
      state.virtualCouponInfo[key] = payload[key]
    })
  },
  upDateSceneStatusNew(state) {
    // 超省卡自动续费改动
    const { usedXtraCode, coupon, checkout, updatingPrice, usableCouponList, xtraAllProductBagInfo } = state
    const { save_card_activity_discount_price = {}, save_card_price = {}, save_card_price_after_coupon = {}, save_card_total_discount_price = {}, save_card_coupon_discount_price = {} } = checkout?.mall_caculate_info || {}
    const xtraProductAll = Object.values(xtraAllProductBagInfo)
    xtraProductAll.forEach(item => {
      const resultData = item?.productInfo
      const productPriceInfo = resultData?.product_price_info || {} // 超省卡价格信息
      const couponRightsCode = productPriceInfo?.product_dues_coupon_rights?.coupon_code // 会费券码
      const isPreciseType = productPriceInfo.promotion_type == 5 // 特价人群
      const _specialPriceNum = resultData?.product_price_info?._specialPriceNum || deepCopy(productPriceInfo.special_price) // 产品包自身活动价格（不包含券）
      const showDiscount = productPriceInfo?.price_local && productPriceInfo?._specialPriceNum && (+productPriceInfo.price_local > +productPriceInfo._specialPriceNum) //产品包特价
      const selectUsedXtraCode = usedXtraCode === resultData.product_code // 选中产品包和遍历对应的包体
      const { total_discount_price, total_discount_price_with_symbol, arrival_price_with_symbol, arrival_price } = productPriceInfo
      // 超省卡会费券59
      const usableCouponListVir = usableCouponList.filter(item => [59].includes(+item.real_type_id)).map(i => i.coupon)
      if (couponRightsCode && !usableCouponListVir.includes(couponRightsCode)) {
        usableCouponListVir.push(couponRightsCode)
      }
      const applyCouponsVir = coupon.applyCouponsSuccess.some(item => (usableCouponListVir.includes(item.couponCode) && item.couponCode == couponRightsCode)) // 使用会费券成功
      const reducePriceMap = applyCouponsVir ? save_card_total_discount_price : save_card_activity_discount_price //优惠金额
      const specialPriceMap = applyCouponsVir ? save_card_price_after_coupon : save_card_price // 到手价

      const couponPriceMap = applyCouponsVir ? save_card_coupon_discount_price.amountWithSymbol : productPriceInfo?.product_dues_coupon_rights?.discount_value_with_symbol //券优惠金额
      // _discountPrice、_conponPrice、_specialPriceNum深拷贝初始值，不随后续更改
      Object.assign(resultData.product_price_info, {
        _discountPrice: resultData?.product_price_info?._discountPrice || deepCopy(productPriceInfo.reduce_price_with_symbol),  // 产品包自身优惠金额
        _specialPriceWithSymbol: resultData?.product_price_info?._specialPriceWithSymbol || deepCopy(productPriceInfo.special_price_with_symbol), // 产品包自身到手价带币总（不包含券）
        _conponPrice: couponPriceMap || 0, //券优惠金额
        _specialPriceNum // 产品包自身到手价（不包含券）
      })

      if (couponRightsCode && !usedXtraCode) {
        // 没勾选，有会费券赋值
        Object.assign(resultData.product_price_info, {
          reduce_price: total_discount_price,
          reduce_price_with_symbol: total_discount_price_with_symbol, // 优惠金额（开卡优惠）
          special_price: arrival_price,
          special_price_with_symbol: arrival_price_with_symbol, // 到手价（开卡价格）
        })
      }
      if (usedXtraCode && !updatingPrice && selectUsedXtraCode) {
        // 勾选情况下，取计价接口返回的数据
        let xtraPrice = {
          reduce_price: reducePriceMap.amount,
          reduce_price_with_symbol: reducePriceMap.amountWithSymbol, // 优惠金额（开卡优惠）
          special_price: specialPriceMap.amount,
          special_price_with_symbol: specialPriceMap.amountWithSymbol // 到手价（开卡价格）
        }
        Object.assign(resultData.product_price_info, xtraPrice)
      }
      const result = scenceBuildResult({ showDiscount, isPreciseType, couponRightsCode, usedXtraCode: selectUsedXtraCode, applyCouponsVir })

      resultData._xtraStatusInfo = { showDiscount, isPreciseType, couponRightsCode, usedXtraCode, applyCouponsVir } // 会费券场景状态值
      resultData._xtraSceneDiscount = result // 会费券场景
    })
  },
  upDatePrimeSceneStatus(state) {
    // 输出当前符合的场景 https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1247968743
    const { primeProductList, usedPrimeCode, coupon, checkout, updatingPrice, usableCouponList, autoPayList } = state
    // 不深拷贝数据，部分有用到primeProductList，采用引用关联特性，保持primeProductList和state.virtualCouponInfo.newPrimeProductList 同步数据，如需源数据在‘深拷贝存储源数据’
    const { prime_price_discount = {}, prime_price = {}, prime_price_after_coupon = {}, prime_total_discount_price = {}, prime_coupon_discount_price = {} } = checkout?.mall_caculate_info || {}
    const scenceAll = primeProductList.map(item => {
      const { product_price_info: productPriceInfo, product_code } = item
      const couponRightsCode = productPriceInfo?.product_dues_coupon_rights?.coupon_code //会费券码
      const currentResultProPriceInfo = primeProductList.find(f => f.product_code === product_code).product_price_info // 当前包体对应的价格信息
      const selectUsedPrimeCode = usedPrimeCode === product_code
      // 付费会员会费券58
      const usableCouponListVir = usableCouponList.filter(item => [58].includes(+item.real_type_id)).map(i => i.coupon)
      if (couponRightsCode && !usableCouponListVir.includes(couponRightsCode)) {
        usableCouponListVir.push(couponRightsCode)
      }
      const applyCouponsVir = coupon.applyCouponsSuccess.some(item => (usableCouponListVir.includes(item.couponCode)) && item.couponCode == couponRightsCode) // 使用会费券成功

      const { total_discount_price, total_discount_price_with_symbol, arrival_price_with_symbol, arrival_price } = productPriceInfo || {}
      const reducePriceMap = applyCouponsVir ? prime_total_discount_price : prime_price_discount  // 优惠金额
      const specialPriceMap = applyCouponsVir ? prime_price_after_coupon : prime_price // 到手价
      const couponPriceMap = applyCouponsVir ? prime_coupon_discount_price.amountWithSymbol : productPriceInfo?.product_dues_coupon_rights?.discount_value_with_symbol //券优惠金额带币总
      const couponPriceAmountMap = applyCouponsVir ? prime_coupon_discount_price.amount : productPriceInfo?.product_dues_coupon_rights?.discount_value //券优惠金额
      const resultData = { ...item }
      const _specialPriceNum = resultData?.product_price_info?._specialPriceNum || deepCopy(productPriceInfo.special_price) // 产品包自身到手价（不包含券）
      const showDiscount = currentResultProPriceInfo?.price_local && currentResultProPriceInfo?._specialPriceNum && (+currentResultProPriceInfo.price_local > +currentResultProPriceInfo._specialPriceNum) //特价

      // 深拷贝存储源数据
      // _discountPrice、_discountPriceAmount、_conponPrice、_conponPriceAmount为了获取初始值，不随后续更改
      Object.assign(resultData.product_price_info, {
        _discountPrice: resultData?.product_price_info?._discountPrice || deepCopy(productPriceInfo.reduce_price_with_symbol), // 产品包自身优惠金额带币总
        _discountPriceAmount: resultData?.product_price_info?._discountPriceAmount || deepCopy(String(productPriceInfo.reduce_price)),
        _conponPrice: couponPriceMap || 0, //券优惠金额带币总
        _specialPrice: resultData?.product_price_info?._specialPrice || deepCopy(productPriceInfo.special_price_with_symbol),  // 产品包自身到手价带币总（不包含券）
        _conponPriceAmount: couponPriceAmountMap || 0, //券优惠金额
        _specialPriceNum, // 产品包自身到手价（不包含券）
      })

      if (couponRightsCode && !usedPrimeCode) {
        // 有会费券，没勾选
        // total_discount_price_with_symbol券后优惠，reduce_price_with_symbol折扣优惠，special_price_with_symbol折扣价格，arrival_price_with_symbol券后价格
        Object.assign(resultData.product_price_info, {
          reduce_price: total_discount_price,
          reduce_price_with_symbol: total_discount_price_with_symbol, // 开卡优惠
          special_price: arrival_price,
          special_price_with_symbol: arrival_price_with_symbol, // 开卡价格
        })
      }
      if (usedPrimeCode && !updatingPrice && selectUsedPrimeCode) {
        let primePrice = {
          reduce_price: reducePriceMap.amount,
          reduce_price_with_symbol: reducePriceMap.amountWithSymbol, // 优惠金额（开卡优惠）
          special_price: specialPriceMap.amount,
          special_price_with_symbol: specialPriceMap.amountWithSymbol,  // 开卡价格
        }
        Object.assign(resultData.product_price_info, primePrice)
      }

      let result = 0
      if (showDiscount && couponRightsCode && !selectUsedPrimeCode && !applyCouponsVir) result = 8
      if (showDiscount && couponRightsCode && selectUsedPrimeCode && applyCouponsVir) result = 10
      if (!showDiscount && couponRightsCode && selectUsedPrimeCode && !applyCouponsVir) result = 6
      if (showDiscount && couponRightsCode && selectUsedPrimeCode && !applyCouponsVir) result = 9
      if (showDiscount && !couponRightsCode && !selectUsedPrimeCode) result = 3
      if (showDiscount && !couponRightsCode && selectUsedPrimeCode) result = 4
      return {
        scence: result,
        product_dues_coupon_rights: resultData.product_price_info?.product_dues_coupon_rights,
        product_code: product_code,
        status: { showDiscount, couponRightsCode, selectUsedPrimeCode, applyCouponsVir },
        primeProductList: resultData,
        couponRightsCode,
        currentResultProPriceInfo,
        autoPayList,
        state
      }
    })
    state.virtualCouponInfo.newPrimeProductList = scenceAll
    state.virtualCouponInfo.couponVirtualCode = scenceAll.map(m => m.couponRightsCode)
    // console.log('current this prime', scenceAll)
  },

  updateDefaultSelectTokenPay(state, defaultSelectTokenPay = {}) {
    state.defaultSelectTokenPay = Object.assign({}, defaultSelectTokenPay)
  },

  updateIsShowTokenPay(state, isShowTokenPay = false) {
    state.isShowTokenPay = isShowTokenPay
  },
  updateShippingAddStatusInfo(state, payload) {
    Object.keys(payload)?.forEach(key => {
      state.shippingAddStatusInfo[key] = payload[key]
    })
  },

  updateCreateOrderBin(state, createOrderBin = '') {
    state.createOrderBin = createOrderBin
  },
  updateCreateOrderBinDialogShow(state, createOrderBinDialogShow = false) {
    state.createOrderBinDialogShow = createOrderBinDialogShow
  },
  updateIsNoNeedCardBin(state, isNoNeedCardBin = false) {
    state.isNoNeedCardBin = isNoNeedCardBin
  },
  changeHomogenizationDrawerVisible(state, isShowHomogenizationDrawer = false) {
    state.isShowHomogenizationDrawer = isShowHomogenizationDrawer
  },
  showCouponListAndAnchor(state, couponCode = '') {
    state.coupon.autoAnchoredCoupon = couponCode
    state.pageStatus.chooseCouponDrawer = true
  },
  updateMxTaxStatus(state, init = false) {
    const taxNoticeStatus = +state.checkout?.abtInfo?.TaxShowType1?.param?.TaxShowTypeStatus
    // 墨西哥订单，或者墨西哥站点
    const isMx = ['MX'].includes(state.checkout?.default_address?.value) || !!~state.locals.SiteUID.indexOf('mx')
    const { tax = [], total_tax } = state.checkout?.mall_caculate_info || {}
    // 实际税金（公司支付 + 个人支付）
    const hasActualTax = +total_tax?.amount > 0
    // 实际补贴（公司支付）
    const hasActualSubsidy = tax.find(item => item.taxType == 1 && item.taxPayType == 1 && +item.taxPrice.amount > 0)
    const taxNoticeVisible = isMx && hasActualTax && hasActualSubsidy
    if (![2, 3].includes(taxNoticeStatus) || !taxNoticeVisible) {
      state.mxTaxNotice = {
        dialogVisible: false,
        fixedNoticeVisible: false
      }
      return
    }
    const dialogDisplayed = +sessionStorage.getItem('checkout_mx_tax_dialog')
    // 只有初始化时满足条件才弹出弹窗，其余情况满足条件后也不弹出
    let dialogVisible = init && !dialogDisplayed && taxNoticeStatus == 3
    // 由于多次触发计价，已经打开的弹窗不再重新获取状态
    if (state.mxTaxNotice.dialogVisible) dialogVisible = true
    state.mxTaxNotice = {
      dialogVisible,
      fixedNoticeVisible: [2, 3].includes(+taxNoticeStatus)
    }
  }
}

export default mutations
