/**
 * 视图宽度与商品项个数映射
 * 2 一行二图
 * 2.3 一行2.3图
 * 2.5s 一行2.5图（小图，参考实时反馈轮播列表）
 * 2.9 一行2.9图
 * 3 一行三图
 * 3l 一行三图(宽版)
 * 3.3 一行3.3图 （参考：首页闪购）
 * 3.3l 一行3.3图(宽版)
 * 4.5 一行露4个全图，第5个漏出来一点
 * 1.2 带左侧侧边栏，右侧一行二图（参考收藏Category视图）
 **/
const columnToWidth = {
  '2': 4.76,
  '3': 2.96,
  '3.3': 2.8,
  '3.3l': 2.96,
  '4.3': 2.08,
  '4.5': 1.92,
  '1.2': 3.36
}

/**
 * 设计图宽度与商品项列数映射
 **/
const columnToCutImageSize = {
  '2': 358,
  '2.5s': 128,
  '3': 223,
  '3.3l': 222,
  '4.3': 78,
}

const labelTypeMap = new Map([
  ['gear', '01'],
  ['noGear', '02'],
  ['unifiedName', '03']
])

const views = {
  LIST_MOBILE_VERTICAL_VIEW: 'mobileVerticalView', // 竖版
  LIST_MOBILE_BROADWISE_VIEW: 'mobileBroadwiseView', // 横版
}

// 德国合规价pageId=1384131888
// 法国挪威合规价pageId=1347754607
// normal（接入合规价，但不包含lowest price，对应fr站点处理方案）
// special（接入合规价，并包含lowest price，对应pl站点处理方案）
// special_de（接入合规价，并包含lowest price、原价的样式，对应de站点处理方案
const apolloSuggestedSaleConfig = {
  NORMAL: 'normal',
  SPECIAL: 'special',
  SPECIALDE: 'special_de'
}

export {
  columnToWidth,
  columnToCutImageSize,
  labelTypeMap,
  views,
  apolloSuggestedSaleConfig
}
