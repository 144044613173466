import { checkoutStateFactory } from './state'
import getters from './getters/index.js'
import mutations from './mutations'
import actions from './actions'
import bankSelect from '../sub_modules/bank-select'
import { bankSelectStateFactory } from '../sub_modules/bank-select'
import tokenPayModule, { tokenPayStateFactory } from '../sub_modules/token-pay'

export function stateFactory (module) {
  if(module === 'bankSelect') return bankSelectStateFactory()
  if (module === 'tokenPay') return tokenPayStateFactory()
  return checkoutStateFactory()
}
export default {
  namespaced: true,
  state: checkoutStateFactory(),
  getters,
  mutations,
  modules: {
    bankSelect,
    tokenPay: tokenPayModule
  },
  actions
}
