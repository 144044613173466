import schttp from 'public/src/services/schttp'
import UserInfoManager from 'public/src/services/UserInfoManager'

export const queryMemberBuyProduct = () => {
  return schttp({
    method: 'POST',
    url: '/api/checkout/memberBuyProduct/get',
    params: {
      scene: 1
    }
  }).then(res => {
    let product_info_list = []
    if ( res.code == 0 && res.info ) {
      product_info_list = res.info?.product_info_list || []
    }
    return product_info_list
  }).catch(() => {
    return []
  })
}

export const queryProductsByMember = () => {
  return new Promise(resolve => {
    schttp({
      method: 'POST',
      url: '/api/checkout/queryProductsByMember/get',
      data: {
        scene: 1
      }
    }).then(res => {
      if(res.code == 0 && !!res.info?.result?.length) {
        resolve(res.info?.result[0] || {})
      } else {
        resolve({})
      }
    }).catch(() => {
      resolve({})
    })
  })
}

export const queryXtraPaymentrights = ({ xtraProductBagInfo, primeCode, checkout_no }) => {
  // rights_type 权益类型 1-超省卡权益  0-付费会员
  let payment_right = {}
  let allPaymentRightInfo = {}
  const product_code = xtraProductBagInfo?.map(_ => _.product_code)
  
  if(!product_code?.length) return { payment_right, allPaymentRightInfo }
  const payment_rights = product_code?.map(i => ({ product_code: i, rights_type: 1 }) )

  if(primeCode) payment_rights.push({ product_code: primeCode, rights_type: 0 })
  const params = {
    payment_rights,
    is_now_buy: !!checkout_no ? 1 : 0, // 是否为立即购买,0-否,1-是,非1场景均按照非立即购处理
    checkout_no: checkout_no,
  }

  return new Promise(resolve => {
    schttp({
      method: 'POST',
      url: '/api/checkout/queryPaymentRights/query',
      data: params
    }).then(res => {
      if(res.code == 0 && !!res.info?.payment_rights?.length) {
        res.info?.payment_rights?.forEach(i => allPaymentRightInfo[i.product_code] = i)
        payment_right = res.info?.payment_rights[0] || {}
        resolve({ payment_right, allPaymentRightInfo })
      } else {
        resolve({})
      }
    }).catch(() => {
      resolve({})
    })
  })
}

export const getPrimeSavingSummary = (params) => {
  return new Promise(resolve => {
    schttp({
      method: 'POST',
      url: '/api/prime/savingsSummary/get',
      data: params
    }).then(res => {
      if(res.code == 0) {
        resolve(res.info)
        return
      }
      resolve({})
    }).catch(() => {
      resolve({})
    })
  })
}

// 查询权益优惠券列表
export const getCouponByCodes = async(codes) => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/postCouponByCouponCodes/query',
    data: { codes }
  })
  return res
}

// 获取第二语言
export const getStateCitySecondLanguage = async (params) => {
  const res = await schttp({
    url: '/api/user/addressbook/stateCitySecondLanguage/get',
    params
  })
  return res
}

export const getSpuProductsCart = async(carts) => {
  let params = []
  carts.forEach(item => {
    params.push({
      goods_id: item.product?.goods_id || item.goods_id,
      mall_code: item.product?.mall_code || item.mall_code,
      sku_code: item.product?.sku_code || item.sku_code,
    })
  })
  const res = await schttp({
    method: 'POST',
    url: '/api/checkout/getSpuProductsCart/query',
    params: {
      carts: JSON.stringify(params)
    },
    json: true,
  })
  return res
}

/**
 * 更新购物车数量
 * @param {String} quantity 数量
 * @param {String} id 购物车id
 */
let updateAbortController
export const updateCartItem = ({ quantity, id, checkout_no }) => {
  updateAbortController?.abort()
  updateAbortController = new SchttpAbortCon()
  return schttp({
    url: !!checkout_no ? '/api/checkout/getPapidUpdateCart/update' : '/api/cart/update',
    method: 'POST',
    data: {
      quantity,
      id,
      checkout_no,
      cart_prime_product_code: ''
    },
    signal: updateAbortController?.signal
  })
}

export const deleteCartItem = ({ id, buyNowNo, buyNowNoParams }) => {
  if(buyNowNo){
    // 立即购场景删除商品调用
    return schttp({
      url: '/api/checkout/immediatelyDelete/delete',
      method: 'POST',
      data: buyNowNoParams
    })
  }
  return schttp({
    url: '/api/cart/delete',
    method: 'POST',
    data: {
      id,
      cart_prime_product_code: ''
    }
  })
}

export const getUserInfo = () => {
  return new Promise(resolve => {
    if (gbCommonInfo?.user && Object.keys(gbCommonInfo?.user || {}).length) {
      resolve(gbCommonInfo?.user || null)
      return
    }
    UserInfoManager
      .get({ key: 'UserInfo', actionType: 'userInfo'  })
      .then(res => resolve(res))

    // schttp({
    //   url: '/api/user/center/fetchUserInfo/get'
    // }).then( res => {
    //   resolve(res?.user || null)
    //   return
    // }).catch( () => {
    //   resolve(null)
    // })
  })
}
