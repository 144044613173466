import schttp from 'public/src/services/schttp'
import newBffUtils from './utils'

const { currency, appLanguage } =
  typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

let versionV1 = ['checkout_v1']
let versionV2 = ['checkout_v2']
// 灰度时发现fetchGetStoreAddressApi 接口数据不一样，临时无法补全，推后一个版本
let versionV3 = ['checkout_v3']

newBffUtils.addVersion([...versionV1, ...versionV2])

//  查询支付域图标信息
export const fetchPayDomainLogoApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    // node bff loginc, default payment_code is routepay-card
    if (!data.payment_code) {
      data.payment_code = 'routepay-card'
    }
    return await schttp({
      method: 'GET',
      url: `/order/get_payment_page_logo`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: data,
      useBffApi: true
    })
  } else {
    return await schttp({
      url: '/api/checkout/payDomainLogo/get',
      params: data
    })
  }
}
// 获取ccc弹窗内容
export const fetchChemicalsIdsApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    const { code, info } = await schttp({
      method: 'GET',
      url: `/ccc/dialog/content`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: {
        matchTagId: data.matchTagIds
      },
      useBffApi: true
    })
    if (code == '0' && info?.content) {
      return info.content
    }
    return ''
  } else {
    let p65Text = ''
    const res = await schttp({
      url: `/api/checkout/chemicalsIds/get`,
      params: {
        matchTagIds: JSON.stringify([data.matchTagIds])
      }
    })
    res?.data?.forEach(item => {
      let { tagId = '', content = [] } = item
      if (data.matchTagIds == tagId) {
        p65Text = content?.[0]?.content?.props?.items?.[0]?.content || ''
      }
    })
    return p65Text
  }
}

// 校验店配地址
export const fetchCheckStoreAddressApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    const res = await schttp({
      method: 'POST',
      url: `/user/address/store/check`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: {
        storeCountryId: data.countryId
      },
      useBffApi: true
    })
    if (res.code !== '0' && res?.msg) {
      res.tips = res.msg
    }
    return res
  } else {
    return await schttp({
      method: 'POST',
      url: `/api/checkout/checkStoreAddress/get`,
      data: data
    })
  }
}

// 查询钱包交易记录数
export const fetchWalletInfoApi = async () => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    return await schttp({
      method: 'POST',
      url: '/user/wallet/freezing',
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      useBffApi: true
    })
  } else {
    return await schttp({
      url: '/api/checkout/walletInfo/get'
    })
  }
}
// ccc券列表公告
export const fetchCouponListNoticeApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    const res = await schttp({
      method: 'GET',
      url: `/order/checkout/coupon/notice`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: data,
      useBffApi: true
    })
    if (res?.code === '0' && res?.info?.content) {
      res.isOk = true
      res.data = res.info
    }
    return res
  } else {
    const res = await schttp({
      url: '/api/checkout/communalNoticeCouponlist/get',
      params: data
    })
    if (res?.code == '1000' && res.data) {
      res.isOk = true
    }
    return res
  }
}

// 验证台湾姓名
export const fetchCheckTwNameApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV2)
  if (isNewBff) {
    const res = await schttp({
      method: 'POST',
      url: `/user/addressbook/checkTwName`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      data: data,
      useBffApi: true
    })
    return res
  } else {
    return await schttp({
      url: '/api/user/addressbook/checkTwName/get',
      params: data
    })
  }
}

// 地址更新
export const fetchUpdateExtraAddressBookApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV2)
  if (isNewBff) {
    const res = await schttp({
      method: 'POST',
      url: `/user/addressbook/extraAddress/update`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: {
        address_id: data.address_id
      },
      data: data.data,
      useBffApi: true
    })
    return res
  } else {
    return await schttp({
      method: 'POST',
      url: '/api/user/addressbook/extraAddress/update',
      params: {
        address_id: data.address_id
      },
      data: data.data
    })
  }
}

// 地址删除

export const fetchDelAddressBookApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV2)
  if (isNewBff) {
    const res = await schttp({
      method: 'POST',
      url: `/user/addressbook/address/delete`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: {
        address_id: data.addressId
      },
      useBffApi: true
    })
    return res
  } else {
    return await schttp({
      url: '/api/user/addressbook/list/delete',
      params: data
    })
  }
}

// 灰度时发现fetchGetStoreAddressApi 接口数据不一样，临时无法补全，推后一个版本
// 获取用户店配地址
export const fetchGetStoreAddressApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV3)
  if (isNewBff) {
    const res = await schttp({
      method: 'POST',
      url: `/user/addressbook/getStoreAddress`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: {
        country_id: data.countryId
      },
      useBffApi: true
    })
    if(res.code == 0 && res?.info){
      res.isOk = true
      res.info.result = res.info
    }
    return res
  } else {
    return await schttp({
      method: 'POST',
      url: '/api/user/addressbook/storeAddress/get',
      data: data
    })
  }
}

// 获取国家级手机区号
export const fetchTelCountryListApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV2)
  if (isNewBff) {
    const res = await schttp({
      method: 'GET',
      url: `/user/address/get_country_site_list`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      useBffApi: true
    })
    if (res.code == 0 && res?.info?.country?.item_cates?.length) {
      let contryInfo = res.info.country.item_cates.find(
        item => item.id == data.country_id
      )
      if (contryInfo) {
        res.isOk = true
        res.source = res.info
        res.info = contryInfo
      }
    }
    return res
  } else {
    const res = await schttp({
      url: '/api/checkout/telCountryList',
      params: data
    })
    if (res.code === '0' && res.info?.value && res.info?.countrynum) {
      res.isOk = true
    }
    return res
  }
}
